@charset "utf-8";

.btn{
  display: block;
  text-indent: -9999px;
  text-align: left;
}

a.btn-googlemap{
  display: block;
  border: 1px solid #ccc;
  text-align: center;
  padding: 0.8em;
  margin: 1.5em auto;
  width: 320px;
  color: #ccc;
  -webkit-border-radius: $btn_radius;
  -moz-border-radius: $btn_radius;
  -ms-border-radius: $btn_radius;
  -o-border-radius: $btn_radius;
  border-radius: $btn_radius;
  text-decoration: none;
  line-height: 1.0;
  vertical-align: bottom;
  
  &:hover{
    border-color: #fff;
    color: #fff;
  }
}


h2{
    font-size: 21px;
}

.blue{
    color: #0086D3;
}

.notes{
    
    
    li{
        padding-left: 1em;
        text-indent: -1em;
        text-align: left;
    }
}

.text6{font-size: 37.5% !important;}
.text7{font-size: 43.8% !important;}
.text8{font-size: 50% !important;}
.text9{font-size: 56.3% !important;}
.text10{font-size: 62.5% !important;}
.text11{font-size: 68.8% !important;}
.text12{font-size: 75% !important;}
.text13{font-size: 81.3% !important;}
.text14{font-size: 87.5% !important;}
.text15{font-size: 93.8% !important;}
.text16{font-size: 100% !important;}
.text17{font-size: 106.3% !important;}
.text18{font-size: 112.5% !important;}
.text19{font-size: 118.8% !important;}
.text20{font-size: 125% !important;}
.text21{font-size: 131.3% !important;}
.text22{font-size: 137.5% !important;}
.text23{font-size: 143.8% !important;}
.text24{font-size: 150% !important;}

.text25{font-size: 156.3% !important;}
.text26{font-size: 162.5% !important;}
.text27{font-size: 168.8% !important;}
.text28{font-size: 175% !important;}
.text29{font-size: 181.3% !important;}
.text30{font-size: 187.5% !important;}
.text31{font-size: 193.8% !important;}
.text32{font-size: 187.5% !important;}

.btn-textlink{
    display: block;
    border: 1px solid #ccc;
    text-align: center;
    padding: 0 0.5em;
    margin: 1.5em auto;
    width: 360px;
    height: 3em;
    line-height: 3;
    color: #ccc;
    font-weight: normal;
    -webkit-border-radius: $btn_radius;
    -moz-border-radius: $btn_radius;
    -ms-border-radius: $btn_radius;
    -o-border-radius: $btn_radius;
    border-radius: $btn_radius;
    text-decoration: none;
        
    &:hover{
        border-color: #fff;
        color: #fff;
    }
}

/** _blank link 
a.external {
    background: url("../i/external.gif") no-repeat right center;
    padding-left: 0px;
    text-decoration: none;
  
    &:hover {
        background: url("../i/external.gif") no-repeat left center;
    }
}
*/

/** adobe reader */
.dl_adobereader {
    padding: 1em 2em;
    border: 1px solid #dcdcdc;
    overflow: hidden;
    *zoom: 1;
    
    p{
        padding:0;margin:0;
    }
    
    .bnr {
        float: left;
    }
    .context {
        margin-left: 180px;
    }
}

/** ページトップ */
.pagetop{}
.btn-pagetop{
    width: 65px;
    height: 50px;
    background: url('../i/btn-pagetop.png') no-repeat 0 0;
    margin: auto;
    
    &:hover{
        background-position: 0 -50px;    
    }
}

/** リクエストボタン */
.request{
    padding-top: 30px;
}

ul.nav-request{
  padding: 0;
  margin: 0 0 0 -1px;
  list-style: none;
  
  li{
    float:left;
    margin: 0 1px 1px 0;
  }
      
  a{
    display: block;
    width: 239px;
    height: 120px;
    background-image: url('../i/nav-request.png');
    background-repeat: no-repeat;
  }
  .btn-democar{
    background-position: 0px 0;
        
    &:hover{
      background-position: 0px -122px;    
    }
  }
  .btn-estimate{
    background-position: -240px 0px;
        
    &:hover{
      background-position: -240px -122px;
    }
  }
  .btn-catalog{
    background-position: -480px 0px;
        
    &:hover{
      background-position: -480px -122px;
    }
  }
  .btn-store{
    background-position: -720px 0px;
        
    &:hover{
      background-position: -720px -122px;
    }
  }
}