@charset "utf-8";

$mi_radius: 3px;
$btn_radius: 3px;

.header-container{
    background: #000;
    /** border-bottom: 1px solid #111; */
    /** position: fixed; */
    width: 100%;
    z-index: 999;
   
    .wrapper{
        position: relative;
        background: transparent url('../i/logo-mazda.png') no-repeat left center;
        height: 115px;
        width: 960px;
    }
    
    .corporate-logo {
	    position: absolute;
        top: 21px;
		left: 140px;
		line-height: 1px;
    }
    
    .global-nav {
      position: absolute;
      right: 0px;
      top: 55px;
      width: 820px;
      z-index: 10;
        
        ul{
            margin: 0;
            padding: 0;
            
            li {
                float: left;
                overflow:hidden;
                
                a{
                    display: block;
                    color:#e5e5e6;
                    text-decoration: none;
                    border-bottom:#333 solid 2px;
                    padding: 2px 6px 5px 6px;
                    font-size: 12px;
                    line-height: 26px !important;
                    
                    &:hover,
                    &.selected{
                        color:#FFF;
                        border-bottom:#fff solid 2px;
                    }
                }
            }
        }
    }
    
  div.search {
		position: absolute;
		top: 5px;
		right: 5px;
	}
	
	#srchBox{
    width:298px;
    _width:296px;
    border-style:none;
    color:#fff;
    text-align:left;
  }
  #srchBox *{
    margin:0;
    padding:0;
    color: #333;
    font-size:13px;
    line-height: 1.5;
    *font-size:small;
    *font:x-small;
  }
  #srchBox a img{
    border:none;
  }
  #srchBox #srch{
    padding:10px 10px 0 10px;
  }
  #srchBox #srch #srchForm{
    white-space:nowrap;
  }
  #srchBox #srchInput{
    width:188px;
    margin-right:6px;
    vertical-align:bottom;
    border:none;
    
  }
  #srchBox #srchBtn{
   width:80px;
   border: none;
  }
  
  #srchBox ul{
    margin-top:6px;
    text-align:left;
  }
  #srchBox li{
    list-style-type:none;
    display:inline;
    zoom:1;
    padding-right:10px;
  }
  #srchBox li input{
    zoom:1;
    margin-right:2px;
    _margin:-4px 0 -4px -4px;
    vertical-align:middle;
    border:0;
  }
  
  #srchBox #srchLogo{
    margin: 3px 15px 6px 0;
    text-align: right;
  }
  #srchBox #srchLogo a{
    color:#999;
    text-decoration:none;
    font-size:85%;
  }
  #srchBox #srchLogo a:hover{
    text-decoration:underline;
  }
}