@charset "utf-8";

$mi_radius: 3px;
$btn_radius: 3px;

/** footer nav */
.breadcrumb-container{
    padding: 1em 0;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
    color: #fff;
        
    .inner{
        width: 960px;
        margin: auto;
    }
    
    a{
        color: #aaa;
        text-decoration: none;
        
        &:hover{
            color: #fff;
            text-decoration: underline;
        }
    }
}

.footer-container{
    margin-top: 0px;
    color: #FFF;
    font: 13px/1.231 "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", Arial, verdana, sans-serif !important;
    *font-size: small;
    /* for IE */
    *font: x-small;
    /* for IE in quirks mode */
    
    .col_1,
    .col_2,
    .col_3{
        float:left;
        
        dl,
        dt,
        dd{
            margin: 0;
            padding: 0;
        }
    }
    .col_1{
        margin: 0px;
        width: 225px;
    }
    .col_2{
        margin: 0 0 0 20px;
        width: 470px;
    }
    .col_3{
        margin: 0 0 0 20px;
        width: 225px;
    }
    
    h2.wrap_ttl{
        /** padding: 0.3em 0.9em; */
        margin-bottom: 15px;
        color: #fff;font-weight:bold;
        -webkit-border-radius: $mi_radius;
        -moz-border-radius: $mi_radius;
        -ms-border-radius: $mi_radius;
        -o-border-radius: $mi_radius;
        border-radius: $mi_radius;
        position:relative;
        behavior:url("/common/lib/PIE/PIE.htc");
        -webkit-transform: translate3d(0, 0, 0); /** ちらつき対策 */
    }
    
    /** フッターナビ */
    .inner_wrap_b {
		width: 960px;
		padding:20px 0;
		border-top: 1px solid #505050;
		/*position: relative;*/
		margin: 0 auto;
		overflow: hidden;
		_zoom: 1;
	}
    
    ul.footer-nav{
        list-style: none;
        margin: 0px 0 0;
    
        li{
            float: left;
            margin-right: 15px;
            
            a{
                padding-left: 10px;
                line-height: 1.0;
                text-decoration: none;
                color: #ddd;
                
                i{
                    color: #0086D3;
                }
                
                &:hover{
                    color: #fff;
                }
            }
        }
    }
    
    /** コピーライト */
    .copyright{
        margin-top: 7px;
        padding-top: 7px;
        text-align: right;
    }
    
    /** 会社情報 */
    .box_corporate,
    .box_opened{
        h2{
            margin-bottom: 0.5em;
        }
    }

    /** モバイルサイトの案内 */
    .box_mobilesite{
        
        h2{
            margin-bottom: 0.3em;
        }
    
        .qrcode{
            display: block;
            margin: 10px auto 10px auto; 
        }
    }

    /** 営業時間 */
    .box_opened{
        margin-top: 2em;
        
        dt{
            margin-bottom: 0.3em;
            font-weight:bold;
            color: #0081B1;
        }
        
    }
}

/** 営業カレンダーレイアウト */
ul.cals{
    margin: 0;
    padding: 0;   
    
    li{
        margin: 0;
        padding: 0;
    }
}

/** 年間カレンダーボタン */
.btn-calendar{
    position: absolute;
    right: 0px; top:0;
    display: block;
    width: 10em; height: 21px;
    text-align: center;
    font-size:12px;
    line-height: 21px;
    text-decoration: none;
    color: #ccc;
    border:  1px solid #ccc;
    border-radius: 3px;
    padding-left: 6px;
    font-weight: normal;

    &:hover{
        color: #fff;
        border:  1px solid #fff;
    }
}