@charset "utf-8";

/*** 営業カレンダー */
.cal {
    border-top: none;
    border-left: none;
    width: 225px;
  
    caption {
        text-align: left;
        font-weight: bold;
        padding-bottom: 0.5em;
        
        strong{
            font-size: 124%;
        }
    }    
    th,
    td {
        border-right: 1px solid #111111;
        border-bottom: 1px solid #111111;
        padding: 5px 7px;
        text-align: center;
        line-height: 1.1;
        color:#FFF;
        background:#222;
    }
    thead {
        background: #677580;
        color: #FFF;
        
        span {
            color: #FFF;
        }
    } 
    
    tbody {
        background: #fff;
    }
    .closed {
        background: #444;
        span {color: #FFF; }
    }
    .sun span {color: #960000; }    
    .sat span {color: #005EBC; }
    .holiday span {color: #960000; }
    .closed 
    .closedAll {
        background: #444;
        
        span {color: #FFF; }
    }
    .cal_tit{
        width: 225px;
    }
}
.hanrei {
    margin-top: 0.5em;
    padding: 0;
    list-style: none;
    
    .event {
        color: #FFD907; }
    .closed {
        color: #666; }
    .closedAll {
        color: #548da6; }
}